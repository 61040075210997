import { Component, OnInit, HostBinding, model, inject } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SubscribableBaseComponent } from '../../../shared/components/subscribable-base/subscribable-base.component';
import type FlagEventModel from '../../../shared/models/flag-event.model';
import { GENERAL } from '../../../app.constants';
import { FlagsService } from '../../../shared/services/flags.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlagEventComponent } from '../../../shared/components/flag-event/flag-event.component';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-event-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, FlagEventComponent, MatProgressSpinnerModule],
	templateUrl: './event-handler.component.html',
	styleUrls: ['./event-handler.component.scss']
})
export class EventHandlerComponent extends SubscribableBaseComponent implements OnInit {
	readonly #flagsProvider = inject(FlagsService);
	readonly #router = inject(Router);

	@HostBinding('class.handler') readonly handlerClass = true;
	/** Although required, might be unresolvable by resolver; thus the `@if` in the template */
	readonly event = model.required<FlagEventModel>({alias: 'flagEvent'}); // from route resolver
	eventText = 'Event';

	ngOnInit() {
		// Subscribe to current Flag event editions
		this.#flagsProvider.flagEventCreatedOrEdited()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(flagEvent => { this.event.set(flagEvent); });
	}

	moveAway() {
		this.#router.navigate([GENERAL.handlers.wall.path]);
	}
}
